@font-face {
  font-family: 'MarkPro';
  src: url('../assets/fonts/MarkPro/MarkPro.otf') format('opentype');
}

@font-face {
  font-family: 'MarkProLight';
  src: url('../assets/fonts/MarkPro/MarkPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'MarkProMedium';
  src: url('../assets/fonts/MarkPro/MarkPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'MarkProBold';
  src: url('../assets/fonts/MarkPro/MarkPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'FontAwesomeBold';
  src: url('../assets/fonts/FontAwesome/FontAwesome5Pro-Solid.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesomeLight';
  src: url('../assets/fonts/FontAwesome/FontAwesome5Pro-Light.ttf') format('truetype');
}